<template>
    <breadCrumb/>
    <career/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import career from '../components/career/career.vue'
export default {
    components: {
        breadCrumb,
        career
    },
    data(){
        return{}
    }
}
</script>