<template>
    <section id="ft-goodness-feature" class="ft-goodness-feature-section">
		<div class="container">
			<div class="ft-section-title-2 headline pera-content text-center mt-5" style="max-width: unset;"> 
				<h2>Career</h2>
				<p class="mb-3" v-for="item in mainData.desc" :key="item.id">{{ item.row1 }}</p>
                <p>You may send your resume at <b>hr@winwinlines.com</b> with “Careers” in the subject line.</p>
			</div>
		</div>
	</section>
</template>


<script>
import data from '../../data/data.json'
export default {
    data(){
        return{
            mainData: ""
        }
    },
    created(){
        this.mainData = data.career
    }
}
</script>